import { useQuery } from "@tanstack/react-query"
import { useAuthenticatedAxios } from "."
import type { WorkspacesListResponse } from "@seamapi/http/connect"

type InternalWorkspaceListEntry =
  WorkspacesListResponse["workspaces"][number] & {
    webview_inviter_logo_url: string | null
    webview_logo_shape: string | null
    webview_primary_button_color: string | null
    webview_primary_button_text_color: string | null
    suspended: boolean
  }

export const useWorkspaces = () => {
  const axios = useAuthenticatedAxios()
  return useQuery({
    queryKey: ["workspaces"],
    queryFn: () =>
      axios
        .get("/internal/workspaces/list")
        .then((r) => r.data.workspaces as InternalWorkspaceListEntry[]),
  })
}
